@import '../../../../../../../styles/shared';

.wrapper {
  width: 100%;
  aspect-ratio: 3 / 2;
}

.image {
  background: center no-repeat;
  background-size: cover;
  border-radius: 6px;
  width: 100%;
  height: 100%;
}

.default {
  @include mq($from: desktop) {
    border-radius: 0;
  }
}

.list {
  @include mq($from: desktop) {
    border-radius: 16px;
  }
}

.smallList {
  // use default styles
}
