@import '../../../../../../../styles/shared';

.wrapper {
  & + & {
    margin-top: 40px;
  }
}

.heading {
  font-size: 28px;
  font-weight: 400;
  font-family: var(--hub-article-home-heading-font-family);
  color: var(--hub-article-home-heading-color);
  margin-bottom: 20px;
}

.product {
  margin-bottom: 10px;
  width: 100%;

  &:first-child {
    border-bottom: 1px solid var(--hub-product-module-border-color);
  }

  &:only-child {
    border-bottom: none;
  }
}
