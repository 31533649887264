@import '../../../../styles/shared';

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include mq($from: 900px) {
    width: 900px;
  }

  @media screen and (orientation: landscape) and (max-width: 900px) {
    width: calc(100vh + 100px);
  }
}

.innerWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.header {
  background: #000;
  display: flex;
  justify-content: flex-end;
}

.container {
  position: relative;
}

.button {
  font-size: 16px;
}

.icon {
  margin: 0 2px 2px 0;
}
