@import '../../../../../../styles/shared';

.wrapper {
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 16px;

  @include mq($from: desktop) {
    font-size: 16px;
    line-height: 30px;
    padding-bottom: 20px;
  }
}

.success {
  font-weight: bold;
}

.error {
  color: var(--hub-email-opt-in-module-error-color);
}
