@import '../../../../../../../styles/shared';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;

  @include mq($from: desktop) {
    flex-direction: row;
    align-items: center;
  }
}

.heading {
  font-weight: 700;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 15px;

  @include mq($from: desktop) {
    flex-direction: row;
  }
}
