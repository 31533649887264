@import '../../../../../../../styles/shared';

.wrapper {
  display: inline-flex;
  align-items: center;
  font-family: var(--hub-product-module-card-title-font-family);
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  color: var(--hub-product-module-color);
  padding: 5px 28px 5px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 26px;

  @include mq($from: desktop) {
    font-size: 16px;
    line-height: 24px;
  }
}

.label {
  position: relative;
  top: var(--hub-button-text-top-offset);
  transition: transform 0.3s ease;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--hub-button-contained-background);
  color: #fff;
  width: 18px;
  height: 18px;
  margin-left: 6px;
  border-radius: 50%;
  transition: transform 0.3s ease;

  @include mq($from: desktop) {
    margin-left: 12px;
    width: 24px;
    height: 24px;
  }
}

.icon {
  font-size: 12px;

  @include mq($from: desktop) {
    font-size: 16px;
  }
}

:global(.hub-product-card--hover) {
  .wrapper {
    background-color: var(--hub-button-contained-background);
    color: var(--hub-product-module-color-hover);
  }

  .iconWrapper,
  .label {
    transform: translateX(18px);
  }
}

.small {
  font-size: 12px;
  line-height: 14px;

  .iconWrapper {
    width: 18px;
    height: 18px;
  }

  .icon {
    font-size: 12px;
  }
}

.medium {
  // Uses default styles
}

.large {
  font-size: 12px;
  line-height: 18px;

  @include mq($from: desktop) {
    font-size: 18px;
    line-height: 24px;
  }
}
