@import '../../../../../../styles/shared';

.heading + .paragraph {
  margin-top: 20px;
}

.ctaButton {
  margin-top: 16px;

  @include mq($from: desktop) {
    margin-top: 34px;
  }
}

@include hub-dark-theme() {
  .line {
    background: var(--hub-module-dark-line-color);
  }
}

@include hub-light-theme() {
  .line {
    background: var(--hub-module-light-line-color);
  }
}
