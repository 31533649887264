@import '../../../../../../styles/shared';

.button {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  font-family: var(--hub-button-font-family);
  font-size: 14px;
  padding: 0 20px;
  font-weight: 700;
  line-height: 19px;
  border: none;
  color: var(--hub-button-contained-color);
  background: var(--hub-button-contained-background);

  @include mq($from: desktop) {
    min-height: 50px;
    font-size: 16px;
    padding: 0 40px;
  }
}

.default {
  border-radius: 4px;
}

.rounded {
  border-radius: 66px;
}

.label {
  position: relative;
  top: var(--hub-button-text-top-offset);
}

.icon {
  margin-right: 12px;
}

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}
