.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  line-height: 1;
}

.label {
  font-size: 12px;
}

.body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
