.anchor {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none;
  color: var(--color-scheme-accent-main);

  &:hover {
    text-decoration: underline;
  }
}
