@import '../../../../../../../styles/shared';

.wrapper {
  & + & {
    margin-top: 20px;
  }

  :global([class^='col-'] + [class^='col-']) {
    @include mq($until: tablet) {
      margin-top: 20px;
    }
  }
}
