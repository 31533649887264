@import '../../../../../../../styles/shared';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0 90px 0;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--hub-industry-home-hero-background-color);

  @include mq($from: desktop) {
    min-height: 400px;
    padding: 85px 0 170px;
  }

  &::before {
    @include overlay;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.quote {
  position: relative;
  z-index: 1;
  font-family: var(--hub-industry-home-hero-quote-font-family);
  font-size: 16px;
  padding: 10px 0 10px 40px;
  border-left: 1px solid var(--hub-industry-home-hero-quote-border-color);
  color: var(--hub-industry-home-hero-quote-color);

  @include mq($from: tablet) {
    font-size: 20px;
    line-height: 30px;
  }
}
