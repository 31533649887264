@import '../../../../../../styles/shared';

.wrapper {
  margin-bottom: 25px;
  color: var(--hub-product-module-color);

  @include mq($from: desktop) {
    margin-bottom: 40px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  cursor: pointer;

  @include mq($from: tablet) {
    align-items: flex-end;
  }

  @include mq($from: desktop) {
    margin-bottom: 20px;
  }

  @include mq($from: wide) {
    align-items: center;
  }
}

.heading {
  font-family: var(--hub-module-heading-font-family);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 5px;

  @include mq($from: desktop) {
    font-size: 24px;
  }
}

.toolBar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.resetButton {
  margin-right: 10px;

  @include mq($from: desktop) {
    margin-right: 0;
  }
}

.collapseToggleIcon {
  position: relative;
  top: 2px;
  right: -5px;
  font-size: 24px;

  @include mq($from: desktop) {
    right: -7px;
    font-size: 30px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.listItem {
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.showMoreToggle {
  text-decoration: underline;
}
