@import '../../../../../../../styles/shared';

.wrapper {
  display: inline-flex;
  flex-direction: column;
  gap: 10px;

  @include mq($from: desktop) {
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
}

.label {
  font-size: 16px;
  font-weight: 700;
  color: var(--hub-article-home-heading-color);
}

.body {
  display: inline-flex;
  gap: 20px;
  padding: 5px 15px;
  background-color: #f1f2f4;
  border-radius: 4px;

  a {
    font-size: 22px;
    color: #586876;

    :global(.linkedin) {
      color: #0976b4;
    }

    :global(.twitter) {
      color: #333;
    }

    :global(.facebook) {
      color: #3b5998;
    }

    :global(.link) {
      font-size: 20px;
    }
  }
}
