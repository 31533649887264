@import '../../../../../../styles/shared';

.text {
  font-family: var(--hub-module-text-font-family);
  font-size: 16px;
  line-height: 24px;

  @include mq($from: desktop) {
    font-size: 18px;

    p {
      line-height: 28px;
    }
  }

  :global(.formatted-text) {
    li {
      margin: 10px 0;
      line-height: 1.4;
    }
  }
}

.small {
  font-size: 16px;
  line-height: 24px;
}

.medium {
  font-size: 18px;
  line-height: 28px;
}

.large {
  font-size: 20px;
  line-height: 32px;
}
