@import '../../../../../../styles/shared';

.productWrapper {
  height: 100%;
  padding-bottom: 15px;

  @include mq($from: desktop) {
    padding-bottom: 20px;
  }
}
