@import '../../../../../../../styles/shared';

.wrapper {
  display: flex;
}

.topic {
  &:not(:first-child) {
    display: none;
  }

  @include mq($from: desktop) {
    &:not(:first-child) {
      display: block;
      margin-left: 5px;
    }
  }
}
