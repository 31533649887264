@import '../../../../../styles/shared';

.section {
  position: relative;
}

.wrapper {
  padding: var(--hub-module-padding) 0;
  font-family: var(--hub-module-text-font-family);
}

.body {
  position: relative;
  z-index: 1;
}

:global(.hub-home-hero-module) {
  .body {
    z-index: 2;
  }
}

.noPadding {
  padding: 0;

  @include mq($from: desktop) {
    padding: 0;
  }
}

.brandElements {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

@include hub-dark-theme() {
  .wrapper {
    color: var(--hub-module-dark-color);
    background-color: var(--hub-module-dark-background-color);
  }
}

@include hub-light-theme() {
  .wrapper {
    color: var(--hub-module-light-color);
    background-color: var(--hub-module-light-background-color);
  }
}
