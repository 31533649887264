@import '../../../../../styles/shared';

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.header {
  position: relative;
  z-index: 10;
}

.nav {
  @include mq($until: desktop) {
    display: none;
  }
}

.headerSticky {
  position: relative;
  z-index: 101;
}

.navSticky {
  position: relative;
  z-index: 100;
}

.body {
  flex: 1 0;
}
