@import '../../../../../../../styles/shared';

.wrapper {
  height: 100%;
  border-bottom: 1px solid var(--hub-product-module-border-color);
  padding: 10px;
  overflow: hidden;

  @include mq($from: desktop) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--hub-product-module-color);
    border-radius: 12px;
    padding: 0;

    &:hover {
      .sponsors {
        display: none;
      }
      .largeLogo {
        display: none;
      }
      .description {
        display: block;
      }
    }
  }
}

.image {
  position: relative;
}

.body {
  @include mq($from: desktop) {
    padding: 25px 20px 15px;
  }
}

.topics {
  display: flex;

  & + .title {
    margin-top: 10px;

    @include mq($from: desktop) {
      margin-top: 25px;
    }
  }
}

.mobileOnlyAttendanceType {
  margin-top: 5px;
  display: block;

  @include mq($from: desktop) {
    display: none;
  }
}

.fixedHeight {
  display: none;

  @include mq($from: desktop) {
    display: -webkit-box;
    min-height: 80px;
    margin-top: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; /* start showing ellipsis when 4rd line is reached */
    white-space: pre-wrap;
  }
}

.details {
  margin-top: 10px;

  @include mq($from: desktop) {
    margin-top: 15px;
  }
}

.sponsors {
  display: none;

  @include mq($from: desktop) {
    display: block;
  }
}

.largeLogo {
  img {
    max-width: 100%;
  }
}

.description {
  display: none;
}

.footer {
  display: none;

  @include mq($from: desktop) {
    display: block;
    padding: 0 20px 20px;
  }
}

.leftFooter {
  display: flex;
  justify-content: flex-start;
}

.rightFooter {
  display: flex;
  justify-content: flex-end;
}
