.wrapper {
  &:before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }
  iframe,
  .wistia_embed {
    background: #000;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  iframe > body {
    background: #000;
  }
}
