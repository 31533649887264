@import '../../../../../../styles/shared';

.wrapper {
  @include mq($from: desktop) {
    padding-right: 40px;
  }
}

.title {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 12px;
  font-family: var(--hub-module-heading-font-family);

  @include mq($from: desktop) {
    margin-bottom: 22px;
    font-size: 24px;
  }
}

.description {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 24px;

  @include mq($from: desktop) {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 30px;
  }
}
