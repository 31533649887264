@import '../../../../../../../styles/shared';

.wrapper {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;

  @include mq($from: tablet) {
    padding: 40px;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 20px;
}

.anchor {
  margin-bottom: 25px;
  text-decoration: none;
}

.heading {
  margin-bottom: 25px;
  font-family: var(--hub-authentication-heading-font-family);
  font-size: 32px;
  line-height: 42px;
  color: var(--hub-authentication-heading-color);
}

.text {
  margin-bottom: 35px;
  font-size: 16px;
  color: #333;

  a {
    color: var(--color-scheme-accent-main, inherit);
    text-decoration: underline;
  }
}
