@import '../../../../styles/shared';

$transition-time: 0.25s;

.wrapper {
  position: fixed;
  bottom: -100%;
  width: 100%;
  max-height: 0;
  z-index: 9999;
  transition: max-height 0.6s ease-out, bottom 0.6s ease-out;
  box-sizing: content-box;
  padding: 20px 0;
  font-family: var(--hub-module-text-font-family);
  color: var(--hub-module-dark-color);
  background-color: var(--hub-module-dark-background-color);
  border-top: 2px solid #565656;
}

.collapsedContainer {
  opacity: 0;
  transition: opacity $transition-time;
  transition-delay: $transition-time;
}

.active {
  @extend .wrapper;
  max-height: 100%;
  bottom: 0;
}

.collapsed {
  @extend .wrapper;
  bottom: 0;
  max-height: 45px;
  transition: max-height $transition-time ease-in;
}

.closed {
  @extend .wrapper;
  bottom: -100%;
}
