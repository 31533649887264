.wrapper {
  input,
  select {
    width: 100%;
    height: 45px;
    padding: 0 12px;
    font-size: 16px;
    color: #333;
    border: 1px solid #dee0e5;
    border-radius: 4px;
  }
}

.labelWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #333;
}

.helperText {
  margin-top: 3px;
  font-size: 12px;
}

.error {
  input,
  select {
    border-color: var(--hub-authentication-error-color);
  }

  .helperText {
    color: var(--hub-authentication-error-color);
  }
}
