@import '../../../../styles/shared';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;

  @include mq($from: tablet) {
    flex-direction: row;
    gap: 20px;
  }
}
