@import '../../../../../../../styles/shared';

.wrapper {
  font-family: var(--hub-module-text-font-family);
  color: var(--hub-product-module-color);
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
}

.date,
.deliveryTypes {
  margin-top: 5px;
}

.image {
  flex: 1.2;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 2;
}

.topics {
  display: flex;
  margin-bottom: 10px;
}

.showMoreBadge {
  margin-top: 5px;
}
