@import '../../../../../styles/shared';

.count {
  color: inherit;
  display: none;

  @include mq($from: desktop) {
    display: inline;
  }
}

.leftToolbarWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include mq($from: desktop) {
    flex-direction: row;
  }
}

.resetButton {
  margin-left: 10px;
}

.resetAllButton {
  margin: 5px 0 0 0;

  @include mq($from: desktop) {
    margin: 0 0 0 10px;
  }
}

.removeTopPaddingOnMobile {
  @include mq($until: desktop) {
    padding-top: 0;
  }
}
