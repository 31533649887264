@import '../../../../../../../styles/shared';

.wrapper {
  height: 100%;
  border-radius: 16px;
  padding: 10px;

  @include mq($from: desktop) {
    padding: 20px 15px;

    &:hover {
      .sponsors {
        display: none;
      }

      .description {
        display: block;
      }
    }
  }
}

.image {
  position: relative;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.leftHeader,
.rightHeader {
  display: flex;
  margin-bottom: 10px;

  @include mq($from: desktop) {
    margin-bottom: 20px;
  }
}

.leftHeader {
  justify-content: flex-start;
}

.rightHeader {
  justify-content: flex-end;
}

.details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
}

.date,
.language,
.location,
.deliveryTypes {
  margin-right: 5px;

  @include mq($from: desktop) {
    margin-right: 15px;
  }
}

.desktopOnly {
  display: none;

  @include mq($from: desktop) {
    display: block;
  }
}

.mobileOnly {
  display: block;

  @include mq($from: desktop) {
    display: none;
  }
}

.footer {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 10px;
}

.bigFooter {
  height: 90px;
}

.smallFooter {
  height: 40px;
}

.descriptionWrapper {
  display: none;

  @include mq($from: desktop) {
    display: -webkit-box;
    position: absolute;
    top: 0;
    min-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* start showing ellipsis when 2rd line is reached */
    white-space: pre-wrap;
  }
}

.description {
  display: none;
}

.showMoreBadge {
  margin-top: 5px;

  @include mq($from: desktop) {
    margin-top: 20px;
  }
}

.sponsors {
  display: none;

  @include mq($from: desktop) {
    display: block;
  }
}
