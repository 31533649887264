@import '../../../../../../styles/shared';

.inlineTopic {
  font-weight: 400;
  color: var(--hub-product-module-color);

  @include mq($from: desktop) {
    font-weight: 700;
    color: #82869c;
  }
}

.inlineTitle {
  font-family: var(--hub-product-module-card-title-font-family);
}

:global(.hub-product-card--hover) {
  .inlineTopic {
    @include mq($from: desktop) {
      color: var(--hub-product-module-color-hover);
    }
  }
}
