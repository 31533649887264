@import '../../../../../../../styles/shared';

.primaryTopic {
  margin-bottom: 20px;
  font-size: 20px;
  color: var(--hub-article-home-heading-color);
}

.heading {
  margin: 0 0 30px;
  font-family: var(--hub-article-home-heading-font-family);
  font-size: 40px;
  line-height: 50px;
  color: var(--hub-article-home-heading-color);
}

.image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: 20px 0 25px;
}

.authorWrapper {
  margin-bottom: 30px;
}

.turtlWrapper {
  margin: 30px 0;
}

.downloadFilesWrapper {
  display: flex;
  gap: 10px;
  margin: 30px 0;
}

.downloadFilesWidgetWrapper {
  margin: 20px 0;
}

.shareBarWrapper {
  margin: 25px 0;
}

.content {
  font-size: 18px;
  line-height: 32px;

  h2 {
    margin: 30px 0;
    font-size: 22px;
    line-height: 30px;
  }

  h3 {
    margin: 30px 0 12px;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  }

  h4 {
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-size: 16px;
    font-weight: 700;
  }

  p {
    font-size: 18px;
    line-height: 32px;
  }

  a {
    color: var(--hub-link-color);

    &:hover {
      color: var(--hub-link-color-hover);
    }
  }

  blockquote {
    margin: 44px 0;
    padding-left: 20px;
    font-size: 26px;
    font-weight: 300;
    line-height: 36px;
    border-left: 1px solid #a6bcc6;
  }

  img.alignleft {
    float: left;
    margin: 5px 10px 10px 0;
  }

  img.alignright {
    float: right;
    margin: 5px 0 10px 10px;
  }

  img.aligncenter {
    margin: 32px auto;
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
    margin: 32px 0;
  }

  ul,
  ol {
    li {
      font-weight: 300;
    }
  }

  table {
    width: 100%;

    td,
    th {
      padding: 5px;
    }
  }

  sub {
    font-size: 75%;
  }

  @include mq($until: tablet) {
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
