@import '../../../../../../../styles/shared';

.heading {
  font-family: var(--hub-product-module-card-title-font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.tiny {
  // Uses default styles
}

.extraSmall {
  @include mq($from: desktop) {
    font-size: 20px;
    line-height: 28px;
  }
}

.small {
  @include mq($from: desktop) {
    font-size: 28px;
    line-height: 32px;
  }
}

.medium {
  @include mq($from: desktop) {
    font-size: 30px;
    line-height: 32px;
  }
}

.large {
  @include mq($from: desktop) {
    font-size: 34px;
    line-height: 32px;
  }
}
