.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: var(--color-scheme-accent-main);
  border: 1px solid #ccc;
  border-radius: 50%;
  transition: 0.2s;

  &:hover {
    color: #fff;
    background-color: var(--color-scheme-accent-main);
  }
}

.icon {
  font-size: 20px;
}
