.wrapper {
  display: inline-flex;
  flex-direction: column;
  gap: 30px;
}

.photoWrapper {
  display: flex;
  justify-content: center;
}

.profilePhoto {
  position: relative;
  display: inline-flex;
}

.photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.editPhotoButton {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.editPhotoButtonIcon {
  color: #fff;
  background-color: #999;
  border: 2px solid #fff;
}

.input {
  display: none;
}
