@import '../../../../../../styles/shared';

.button {
  color: var(--hub-product-module-color);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.normal {
  font-size: 16px;
  line-height: 18px;
}

.medium {
  font-size: 18px;
  line-height: 22px;
}

.disabled {
  color: #b5b8c8;
}

.icon {
  margin-left: 5px;
}

.text {
  text-decoration: underline;
}
