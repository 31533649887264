@import '../../../../../../../styles/shared';

.label {
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: var(--hub-product-module-secondary-color);
}

.logosWrapper {
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: nowrap;
  max-width: 100%;

  img {
    height: 45px;
    aspect-ratio: 2/1;
    object-fit: contain;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}
