@import '../../../../../../styles/shared';
@import '../../../FloatingEmailOptInModule/FloatingEmailOptInModule.module';

.container {
  display: flex;
  justify-content: space-between;
}

.leftSide {
  display: flex;
  opacity: 0;
  transition: opacity $transition-time;
  transition-delay: $transition-time;
}

.rightSide {
  opacity: 0;
  transition: opacity $transition-time;
  transition-delay: $transition-time;

  @include mq($from: desktop) {
    opacity: 1;
  }
}

.opacity {
  opacity: 1;
}

.title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  font-family: var(--hub-module-heading-font-family);
  line-height: 30px;
}

.submitButton {
  margin-left: 0;

  @include mq($from: desktop) {
    margin-left: 30px;
  }
}
