.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.input {
  position: absolute;

  input {
    -moz-appearance: initial; //  hack to fix issues with ::before and ::after pseudo-elements not appearing in Firefox browsers

    &:not(:disabled) {
      cursor: pointer;
    }

    &::before {
      content: '';
      position: absolute;
      top: 5px;
      left: 4px;
      z-index: 1;
      width: 12px;
      height: 12px;
      transform: scale(0, 0);
      transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
      background-color: #fff;
    }

    &::after {
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      width: 20px;
      height: 20px;
      background: #fff;
      border: 2px solid var(--color-scheme-primary-main);
    }

    &:checked {
      &::before {
        transform: scale(1, 1);
        background-color: var(--color-scheme-primary-main);
      }
    }
  }

  input[type='checkbox'] {
    &::before {
      border-radius: 2px;
    }

    &::after {
      border-radius: 5px;
    }
  }

  input[type='radio'] {
    &::before {
      border-radius: 6px;
    }

    &::after {
      border-radius: 10px;
    }
  }
}

.label {
  display: block;
  padding-left: 35px;
  font-size: 16px;
  line-height: 22px;
}

.count {
  position: relative;
  top: 1px;
  font-size: 16px;
}
