@import '../../../../../../../styles/shared';

.wrapper {
  & + & {
    margin-top: 70px;
  }
}

.heading {
  margin-bottom: 20px;
  font-family: var(--hub-article-home-heading-font-family);
  font-size: 28px;
  color: var(--hub-article-home-heading-color);

  @include mq($from: desktop) {
    font-size: 40px;
  }
}

.productWrapper {
  @include mq($from: desktop) {
    height: 100%;
  }
}

.showMoreCTA {
  margin-top: 10px;

  @include mq($from: desktop) {
    margin-top: 20px;
  }
}
