.wrapper {
  input {
    width: 100%;
    height: 46px;
    padding: 0 17px 0 13px;
    border-radius: 6px;
    background-color: var(--hub-email-opt-in-module-input-color);
    border: 1px solid var(--hub-email-opt-in-module-input-color);
    border-bottom: 6px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #fff;
    }
  }
}

.error {
  @extend .wrapper;
  input {
    border: 1px solid var(--hub-email-opt-in-module-error-color);
  }
}

.errorMessage {
  color: var(--hub-email-opt-in-module-error-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
