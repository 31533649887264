@import '../../../../../../styles/shared';

.header {
  font-family: var(--hub-module-heading-font-family);
  font-size: 24px;
  line-height: 42px;

  @include mq($from: desktop) {
    font-size: 40px;
    line-height: 48px;
  }
}
