@import '../../../../../../styles/shared';

.wrapper {
  background-color: var(--hub-industry-home-topic-card-background-color);
  border-radius: 16px;
  display: block;
  height: calc(100% - 20px);
  margin-top: 15px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  color: var(--hub-industry-home-topic-card-text-color);

  @include mq($from: desktop) {
    margin-top: 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  flex: 1 0;
  padding: 25px 20px;
  height: 100%;
}

.link {
  position: relative;
  overflow: hidden;
  display: block;
  height: calc(100% - 40px);
  text-decoration: none;
}

.tagsList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.title {
  font-family: var(--hub-industry-home-topic-card-heading-font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;

  @include mq($from: tablet) {
    font-size: 28px;
    line-height: 28px;
  }
}

.description {
  font-size: 14px;
  line-height: 22px;
  color: var(--hub-industry-home-topic-card-description-color);
  flex-grow: 1;

  @include mq($from: tablet) {
    font-size: 16px;
  }
}

.brandsWrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
}

.brandsTitle {
  display: none;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: var(--hub-industry-home-topic-card-brands-title-color);
  min-width: 105px;

  @include mq($from: desktop) {
    display: inline-block;
  }
}

.brandsList {
  span + span {
    margin-left: 10px;
  }

  @include mq($from: desktop) {
    flex-grow: 1;
  }
}

.brandsItem {
  display: inline-block;
  padding-bottom: 4px;

  @include mq($from: desktop) {
    margin-left: 10px;
  }

  img {
    max-height: 32px;
    vertical-align: middle;
  }
}

.ctaWrapper {
  width: 180px;
}
