@import '../../../../../../styles/shared';

$spinner-height: 60px;

.loader {
  display: inline-block;
  position: absolute;
  right: 50%;
  top: 50%;
  width: $spinner-height;
  height: $spinner-height;
  margin-top: -$spinner-height * 0.5;
  margin-right: -$spinner-height * 0.5;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
