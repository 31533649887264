@import '../../../../../../styles/shared';

.wrapper {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 34px;
  color: #333333;

  @include mq($from: desktop) {
    font-size: 18px;
  }
}

.label {
  font-weight: 600;
  border-bottom: 1px solid var(--hub-product-module-border-color);
}
