.anchor {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: #000;

  &:hover .label {
    text-decoration: underline;
  }
}

.label {
}

.icon {
  font-size: 26px;
}
