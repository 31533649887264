@import '../../../../styles/shared';

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 5px;
  color: var(--classic-header-breadcrumbs-color);
}

.listItem {
  position: relative;

  &::after {
    position: absolute;
    top: 1px;
    right: -20px;
    content: 'chevron_right';
    font-family: 'Material Icons', serif;
    font-size: 18px;

    @include mq($from: desktop) {
      top: -3px;
      right: -26px;
      font-size: 22px;
    }
  }
}

.anchor {
  font-size: 14px;
  color: inherit;
  white-space: nowrap;

  @include mq($from: desktop) {
    font-size: 16px;
  }
}
