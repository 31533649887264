@import '../TextField/TextField.module';

.inputWrapper {
  display: flex;

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.visibilityToggle {
  width: 50px;
  font-size: 12px;
  background-color: #fff;
  border: 1px solid #dee0e5;
  border-left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  &:hover {
    background-color: #efefef;
  }
}
