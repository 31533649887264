@import '../../../../../../styles/shared';

.wrapper {
  font-size: 16px;
  color: #333;

  @include mq($from: desktop) {
    font-size: 18px;
  }
}
