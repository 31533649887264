@import '../../../styles/shared';

.container {
  position: absolute;
  z-index: 10000;
  margin-top: 16px;
  width: auto;
  visibility: hidden;
  opacity: 0;
  transition: opacity ease 200ms;
  font-size: 14px;
  border-radius: 30px;
  padding: 12px;
  height: 60px;
  background: rgba(255, 255, 255, 0.85);

  @include right(20px);

  @include mq($from: desktop) {
    margin-top: 12px;
    font-size: 24px;
    border-radius: 3px;
    height: auto;

    @include theme-only($DEFAULT) {
      height: 46px;
      padding-left: 20px;
      padding-right: 15px;
      border-radius: 48px;
    }
  }

  @include theme-only($DEFAULT) {
    height: 40px;
    padding: 0 15px 0 20px;
    border-radius: 48px;
  }
}

.icon {
  vertical-align: bottom;
  font-size: 14px;
  @include margin-left(2px);

  @include mq($from: desktop) {
    font-size: 24px;
    @include margin-left(6px);
  }
}

.visible {
  visibility: visible;
  opacity: 1;
}
