@import '../../../../../../styles/shared';

.wrapper {
  margin-bottom: 40px;
}

.headingWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  color: var(--hub-product-module-color);
}

.heading {
  position: relative;
  top: -1px;
  font-family: var(--hub-module-heading-font-family);
  font-size: 28px;
  font-weight: 700;
}

.subHeading {
  margin-left: 20px;
}

.button {
  margin-top: 20px;
}
