@import '../../../../../../../styles/shared';

.cardWrapper {
  font-family: var(--hub-module-text-font-family);
  color: var(--hub-product-module-color);
}

:global(.hub-product-card--hover) {
  background-color: var(--hub-product-module-card-background-color);
  color: var(--hub-product-module-color-hover);
}
