@import '../../../../../../styles/shared';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.inputWrapper {
  position: relative;
  display: flex;
  width: 100%;

  input {
    flex: 1;
    height: 46px;
    margin: 0;
    padding: 0 0 0 15px;
    font-size: 16px;
    appearance: none;
    color: #000;
    border: none;
    border-radius: 8px 0 0 8px;

    &:focus {
      outline: none;
      background: #f2f2f2;
    }

    @include mq($from: tablet) {
      height: 56px;
      padding: 0 0 0 23px;
      font-size: 18px;
    }
  }
}

.suggestionsWrapper {
  position: absolute;
  top: 46px;
  right: 0;
  left: 0;
  z-index: 1;

  @include mq($from: tablet) {
    top: 56px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  background: var(--color-scheme-accent-main);
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  user-select: none;

  @include mq($from: tablet) {
    width: 56px;
  }
}

.searchIcon {
  font-size: 28px;
  color: #fff;
}

.clearIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 75px;
  width: 15px;
  height: 15px;
  margin: auto;
  font-size: 24px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.3);
  cursor: pointer;

  @include mq($from: desktop) {
    right: 90px;
    font-size: 28px;
  }
}

.bordered {
  input {
    border: 2px solid #d9d9d9;
    border-right: 0;
  }
}

.showSuggestions {
  input {
    border-bottom-left-radius: 0;
  }

  .button {
    border-bottom-right-radius: 0;
  }
}
