.strengthBar {
  margin-top: 10px;
}

.termsText {
  margin-top: 20px;
}

.errorHelperText {
  margin-top: 20px;
}

.submitButton {
  margin-top: 30px;
}
