.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 150px;
  padding: 0 30px;
  font-family: var(--hub-button-font-family);
  font-size: 18px;
  font-weight: 700;
  border: 0;
  border-radius: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.2s;

  &:disabled {
    color: #999;
    background-color: #f1f2f4;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: none;
  }
}

.label {
  position: relative;
  top: var(--hub-button-text-top-offset);
}

.accent {
  color: var(--hub-button-contained-color);
  background: var(--hub-button-contained-background);

  &:not(:disabled):hover {
    color: var(--hub-button-contained-color-hover);
    background: var(--hub-button-contained-background-hover);
  }
}

.success {
  color: #fff;
  background: var(--hub-authentication-success-color);

  &:not(:disabled):hover {
    background: var(--hub-authentication-success-color-hover);
  }
}

.grey {
  color: #333;
  background-color: #f1f2f4;
  border: 1px solid rgba(0, 0, 0, 0.2);

  &:not(:disabled):hover {
    background-color: #d4d7de;
  }
}
