@import '../../../../../../../styles/shared';

.header {
  width: 100%;
  text-align: center;
  padding: 50px 0 30px 0;

  @include mq($from: desktop) {
    padding: 85px 0 70px 0;
  }
}

.withJigsawHeader {
  margin-top: -50px;
  padding: 0 0 30px 0;

  @include mq($from: desktop) {
    margin-top: -85px;
    padding: 0 0 70px 0;
  }
}

.industryJigsaw {
  position: relative;
  top: -100%;
  width: 100%;
  height: 50px;
  border-radius: 60px 60px 0 0;
  background-color: #fff;

  @include mq($from: desktop) {
    height: 85px;
    border-radius: 80px 80px 0 0;
  }
}

.title {
  font-family: var(--hub-industry-home-module-font-family);
  font-size: 24px;
  line-height: 40px;
  color: var(--hub-industry-home-module-color);

  @include mq($from: tablet) {
    font-size: 40px;
    line-height: 48px;
  }
}

.text {
  font-family: var(--hub-industry-home-module-font-family);
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  color: var(--hub-industry-home-module-color);
  margin-top: 12px;

  @include mq($from: tablet) {
    font-size: 22px;
    line-height: 32px;
  }
}

.featuredCard {
  @include mq($from: tablet) {
    margin-top: 0;
    height: 100%;
  }
}
