@import '../../../../../../../styles/shared';

.wrapper {
  padding: 10px 5px;

  @include mq($from: desktop) {
    padding: 10px 10px 10px 25px;
  }
}

.header {
  margin-bottom: 5px;
}

.leftHeader {
  display: flex;
  justify-content: flex-start;
}

.rightHeader {
  display: flex;
  justify-content: flex-end;
}
