@import '../../../../../../styles/shared';

.filterWrapper {
  @include mq($from: desktop) {
    padding-right: 40px;
  }
}

.headingWrapper {
  margin-bottom: 20px;

  @include mq($from: desktop) {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.leftHeading {
  flex: 1;
  margin-bottom: 20px;

  @include mq($from: desktop) {
    margin-bottom: 0;
    padding-right: 24px;
  }
}

.rightHeading {
  display: flex;
  flex-wrap: nowrap;
}

.topFilterWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.topFilter {
  flex: 1 1 auto;

  @include mq($from: tablet) {
    min-width: 255px;
  }
}

.showMoreButton {
  flex: 1 1 auto;

  @include mq($from: tablet) {
    flex: 0 1 auto;
  }
}

.bottomShowMoreButton {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.brandLogo {
  margin-top: 20px;
}

.keywordSearchWrapper {
  margin-bottom: 20px;

  @include mq($from: desktop) {
    margin-bottom: 30px;
  }
}

.keywordSearchToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  @include mq($from: desktop) {
    margin-top: 25px;
  }
}

.body {
  position: relative;
}
