@use 'sass:math';
@import '../../../styles/shared';

.wrapper {
  position: relative;
  min-width: var(--magnifying-glass-search-height);
  min-height: var(--magnifying-glass-search-height);
  color: var(--magnifying-glass-search-color);
  overflow: hidden;
}

.dynamicArea {
  width: 0;
  opacity: 0;
  overflow: hidden;
}

.input {
  width: 100%;
  height: var(--magnifying-glass-search-height);
  // 8px + 24px + 8px = 40px - clear/close button and margin around it
  padding: 0 calc(var(--magnifying-glass-search-height) + 40px) 0
    calc(var(--magnifying-glass-search-height) / 2);
  font-size: 18px;
  color: inherit;
  background: var(--magnifying-glass-search-input-background-color);
  border: none;
  border-radius: var(--magnifying-glass-search-border-radius);
  box-shadow: var(--magnifying-glass-search-box-shadow);

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  &:focus {
    outline: none;
  }
}

.closeButton {
  position: absolute;
  top: 50%;
  right: calc(var(--magnifying-glass-search-height) + 8px);
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
}

.searchButton {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--magnifying-glass-search-height);
  height: var(--magnifying-glass-search-height);
  background: var(--magnifying-glass-search-icon-background-color);
  border: 1px solid var(--magnifying-glass-search-icon-background-color);
  border-radius: var(--magnifying-glass-search-border-radius);
  box-shadow: var(--magnifying-glass-search-box-shadow);
}

.open {
  min-width: var(--magnifying-glass-search-min-width);

  .dynamicArea {
    width: 100%;
    opacity: 1;
  }
}

.animated {
  .dynamicArea {
    // describe transition if required
  }
}
