@import '../../../../../../styles/shared';

.headingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0 15px;

  @include mq($from: tablet) {
    align-items: flex-end;
  }

  @include mq($from: desktop) {
    margin-bottom: 25px;
  }

  @include mq($from: wide) {
    align-items: center;
  }
}

.heading {
  font-family: var(--hub-module-heading-font-family);
  font-size: 24px;
  font-weight: 400;
  color: var(--hub-product-module-heading-color);

  @include mq($from: desktop) {
    font-size: 28px;
    font-weight: 700;
  }
}

.resetFilter {
  position: relative;
  top: 1px;
}

.filterToggleWrapper {
  margin-bottom: 20px;
}
