@import '../../../../styles/shared';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 0 30px;
  font-family: var(--hub-button-font-family);
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  border-radius: 40px;
  transition: background 0.4s, border 0.4s;

  @include mq($from: tablet) {
    display: inline-flex;
  }

  &:hover .arrowIcon {
    transform: translateX(10px);
  }
}

.label {
  position: relative;
  top: var(--hub-button-text-top-offset);
}

.startIcon {
  margin-right: 10px;
}

.endIcon {
  margin-left: 15px;
}

.arrowIcon {
  margin-left: 15px;
  transition: transform 0.4s;
}

.fullWidth {
  @include mq($from: tablet) {
    display: flex;
    width: 100%;
  }
}

.small {
  @include mq($from: desktop) {
    min-height: var(--hub-button-small-min-height);
    padding: var(--hub-button-small-padding);
    font-size: var(--hub-button-small-font-size);
    font-weight: var(--hub-button-small-font-weight);
    border-radius: var(--hub-button-small-border-radius);
  }
}

.medium {
  @include mq($from: desktop) {
    min-height: var(--hub-button-medium-min-height);
    padding: var(--hub-button-medium-padding);
    font-size: var(--hub-button-medium-font-size);
    font-weight: var(--hub-button-medium-font-weight);
    border-radius: var(--hub-button-medium-border-radius);
  }
}

.large {
  @include mq($from: desktop) {
    min-height: var(--hub-button-large-min-height);
    padding: var(--hub-button-large-padding);
    font-size: var(--hub-button-large-font-size);
    font-weight: var(--hub-button-large-font-weight);
    border-radius: var(--hub-button-large-border-radius);
  }
}

.contained {
  color: var(--hub-button-contained-color);
  background: var(--hub-button-contained-background);

  &:hover {
    color: var(--hub-button-contained-color-hover);
    background: var(--hub-button-contained-background-hover);
  }
}

.outlined {
  color: var(--hub-button-outlined-color);
  border: var(--hub-button-outlined-border);
  background: var(--hub-button-outlined-background);

  &:hover {
    color: var(--hub-button-outlined-color-hover);
    border: var(--hub-button-outlined-border-hover);
    background: var(--hub-button-outlined-background-hover);
  }
}

.text {
  color: inherit;
}

.primary {
  color: var(--color-scheme-primary-main);
}

.secondary {
  color: var(--color-scheme-secondary-main);
}

.white {
  color: #fff;
}

.dark {
  color: #000;
}
