@import '../../../../../../../styles/shared';

.wrapper {
  padding: 15px 0;
  border-radius: 0 0 8px 8px;
  background-color: #fff;

  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;

    li {
      padding: 0 15px;

      @include mq($from: desktop) {
        padding: 0 23px;
      }
    }
  }
}

.viewAllResults {
  display: block;
  margin-top: 20px;
  padding: 0 15px;
  color: var(--color-scheme-primary-main);

  @include mq($from: desktop) {
    padding: 0 23px;
  }
}

.normal {
  border: 1px solid #d9d9d9;
  border-top: 0;
}

.bordered {
  border: 2px solid #d9d9d9;
  border-top: 0;
}
