@import '../../../../../../styles/shared';

.wrapper {
  color: var(--hub-product-module-heading-color);

  @include mq($from: desktop) {
    display: flex;
    flex-direction: column;
  }
}

.heading {
  font-family: var(--hub-module-heading-font-family);
  font-size: 24px;
  font-weight: 400;

  @include mq($from: desktop) {
    font-size: 36px;
    font-weight: 700;
  }
}

.text {
  position: relative;
  top: 8px;
  font-size: 18px;
}
