.list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-right: 7px;
}

.item {
  &:not(:last-child)::after {
    content: '•';
    padding: 0 7px;
  }
}
