.wrapper {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 12px;
  color: #626262;

  &.valid {
    color: var(--hub-authentication-success-color);
  }

  &.invalid {
    color: var(--hub-authentication-error-color);
  }
}

.icon {
  font-size: 14px;
}
