@import '../../../../../../styles/shared';

.productWrapper {
  height: 100%;
  padding-bottom: 15px;

  @include mq($from: desktop) {
    padding-bottom: 20px;
  }
}

.inlineProductsWrapper {
  @include mq($from: desktop) {
    padding-bottom: 20px;
  }
}

.inlineProductsList {
  @include mq($from: desktop) {
    border-radius: 12px;
    border: 1px solid var(--hub-product-module-color);
    overflow: hidden;
  }
}

.inlineProduct {
  &:not(:last-child) {
    border-bottom: 1px solid var(--hub-product-module-border-color);

    @include mq($from: desktop) {
      border-color: var(--hub-product-module-color);
    }
  }
}

.featuredProductWrapper {
  &::after {
    content: '';
    display: block;
    border-bottom: 1px solid var(--hub-product-module-border-color);

    @include mq($from: desktop) {
      width: calc(100% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      border-bottom-width: 2px;
    }
  }

  @include mq($from: desktop) {
    &:hover {
      &::after {
        border-bottom-color: transparent;
      }
    }
  }
}

.gutter {
  padding-bottom: 15px;

  @include mq($from: desktop) {
    padding-bottom: 20px;
  }
}
