@import '../../../../../../../../styles/shared';

.wrapper {
  display: none;

  @include mq($from: desktop) {
    position: absolute;
    display: block;
  }
}

.topLeft {
  top: 15px;
  left: 10px;
}

.topRight {
  top: 15px;
  right: 10px;
}

.bottomRight {
  bottom: 15px;
  right: 10px;
}

.bottomLeft {
  bottom: 15px;
  left: 10px;
}
