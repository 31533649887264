.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e7e7e7;
}

.fullName {
  font-family: var(--hub-authentication-heading-font-family);
  font-size: 36px;
  font-weight: 300;
  color: #56595b;
}

.editProfileAnchor {
  &:hover {
    text-decoration: none;
  }
}

.table {
  th {
    min-width: 160px;
    padding: 5px 20px 5px 0;
    text-align: right;
    color: #333;
  }
}
