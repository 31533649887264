@import '../../../../styles/shared';

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 65px $gutter-width;
  font-family: var(--hub-module-text-font-family);
  line-height: 1;
  text-align: center;
  color: var(--color-scheme-primary-main);

  @include mq($from: tablet) {
    padding: 85px 0;
  }
}

.title {
  margin-bottom: 15px;
  font-family: var(--hub-module-heading-font-family);
  font-size: 80px;
  font-weight: bold;
}

.subtitle {
  margin-bottom: 25px;
  font-size: 32px;
}

.text {
  font-size: 18px;
  line-height: 24px;
  color: #333;
}
