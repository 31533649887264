.wrapper {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  color: #000;
}

.title {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  mark {
    font-style: normal;
    color: #52144e;
    background-color: unset;
  }
}

.type {
  font-size: 12px;
  font-weight: 700;
  line-height: 23px;
  white-space: nowrap;
  color: #999;
}

.icon {
  font-size: 18px;
  line-height: 22px;
}

.selected,
.wrapper:hover {
  cursor: pointer;

  .title {
    text-decoration: underline;
  }
}
