@import '../../../../../../styles/shared';

.bold {
  font-weight: 600;
}

.description {
  font-weight: 400;
  line-height: 19px;
}

.badge {
  display: inline-flex;
  padding: 6px;
  color: #fff;
  border-radius: 20px;
  background-color: var(--hub-button-contained-background);

  svg {
    margin-right: 2px;
  }
}
