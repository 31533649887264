@import '../../../../../../styles/shared';

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include mq($from: desktop) {
    gap: 0;
  }
}

.listItem {
  &::after {
    content: '';
    position: relative;
    display: block;
    border-bottom: 1px solid var(--hub-product-module-border-color);

    @include mq($from: desktop) {
      margin: 0 20px;
      border-bottom-width: 2px;
    }
  }

  @include mq($from: desktop) {
    &:hover {
      &::after {
        border-bottom-color: transparent;
      }
    }
  }
}

.loader {
  position: relative;
  height: 120px;
}
