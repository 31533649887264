@import '../../../../../../styles/shared';

.body {
  display: flex;
}

.profilePhoto {
  display: flex;
  justify-content: center;

  @include mq($until: tablet) {
    margin-bottom: 30px;
  }
}
