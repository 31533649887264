.wrapper {
  position: relative;

  i {
    position: absolute;
    right: 12px;
    top: 11px;
  }

  select {
    width: 100%;
    height: 46px;
    padding: 0 17px 0 13px;
    border-radius: 6px;
    border: 1px solid var(--hub-email-opt-in-module-input-color);
    background-color: var(--hub-email-opt-in-module-input-color);
    appearance: none;
    position: relative;

    option {
      background-color: var(--hub-module-dark-background-color);
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #fff;
    }
  }
}

.error {
  @extend .wrapper;
  select {
    border: 1px solid var(--hub-email-opt-in-module-error-color);
  }
}

.errorMessage {
  color: var(--hub-email-opt-in-module-error-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
