@import '../../../../../../styles/shared';

.title {
  margin-bottom: 10px;
  font-family: var(--hub-module-heading-font-family);
  font-size: 36px;
  color: var(--hub-product-module-heading-color);
}

.text {
  font-size: 16px;
  color: #333;
}
