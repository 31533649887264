@import '../../../../../../../styles/shared';

.wrapper {
  font-family: 'Open Sans', sans-serif;
  color: #293368;
  border-bottom: 1px solid rgb(41, 51, 104, 0.11);
  padding-bottom: 20px;
  height: 100%;

  @include mq($from: desktop) {
    padding-top: 20px;
  }
}

.topics,
.title {
  margin-top: 15px;

  @include mq($from: desktop) {
    margin-top: 20px;
  }
}

.topicsListVariant {
  @include mq($from: tablet) {
    margin-top: 0;
  }
}

.fixedHeight {
  display: -webkit-box;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}

.fixedHeightListVariant {
  @include mq($from: desktop) {
    -webkit-line-clamp: 2;
  }
}
