@import '../../../../styles/shared';

.wrapper {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  margin-top: 0;

  @include mq($from: desktop) {
    margin-top: 20px;
  }
}

.fieldPadding {
  padding-bottom: 16px;

  @include mq($from: desktop) {
    padding-bottom: 20px;
  }
}

.formWrapper {
  @include mq($from: desktop) {
    padding-left: 40px;
  }

  div:first-child:has(> input, select):not(:global(.row)) {
    @extend .fieldPadding;
  }
}

.formFooter {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include mq($from: desktop) {
    text-align: right;
    display: block;
  }
}
