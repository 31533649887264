@import '../../../../styles/shared';

.wrapper {
  padding: 65px 0;

  @include mq($from: tablet) {
    padding: 85px 0;
  }
}

.title {
  margin-bottom: 10px;
  font-family: var(--hub-module-heading-font-family);
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;

  @include mq($from: tablet) {
    font-size: 36px;
  }
}

.text {
  font-size: 16px;
  line-height: 24px;
  color: #333;

  @include mq($from: tablet) {
    font-size: 18px;
    line-height: 28px;
  }

  ul {
    list-style: circle;
    margin: 10px 0 0 20px;

    @include mq($from: tablet) {
      margin: 15px 0 0 60px;
    }
  }

  li {
    + li {
      margin-top: 10px;
    }
  }

  a {
    color: var(--color-scheme-accent-main);
  }
}
