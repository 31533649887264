.wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #333;
}

.icon {
  font-size: 22px;
}

.body {
  flex: 1;
}

.heading {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: var(--hub-authentication-heading-color);
}

.text {
  margin-top: 2px;
}

.success {
  .iconWrapper {
    border-color: var(--hub-authentication-success-color);
  }

  .icon {
    color: var(--hub-authentication-success-color);
  }
}

.danger {
  .iconWrapper {
    border-color: var(--hub-authentication-error-color);
  }

  .icon {
    color: var(--hub-authentication-error-color);
  }
}
