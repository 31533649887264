@import '../../../../../../styles/shared';

.line {
  height: 1px;
  background: var(--hub-module-line-color);
}

.lineMargins {
  margin: 16px 0 22px 0;

  @include mq($from: desktop) {
    margin: 24px 0 30px 0;
  }
}
