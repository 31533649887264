.wrapper {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  padding: 5px 10px 4px 0;
  color: inherit;
  border-radius: 15px;
  transition: all 0.2s;
}

.label {
  position: relative;
  top: var(--hub-button-text-top-offset);
  font-family: var(--hub-module-heading-font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.arrowIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: #fff;
  background: var(--color-scheme-accent-main);
  border-radius: 50%;
}

.icon {
  font-size: 16px;
}

:global(.animated-cta-trigger:hover) {
  .wrapper {
    padding-left: 20px;
    color: #fff;
    background-color: var(--color-scheme-accent-main);
  }
}
