@import '../../../../styles/shared';

.wrapper {
  background-color: var(--hub-search-proposition-background-color);
  padding: 50px 0;
}

.title {
  font-family: var(--hub-search-proposition-title-font-family);
  font-size: 32px;
  line-height: 38px;
}

.subTitle {
  font-size: 22px;
  color: var(--hub-search-proposition-subtitle-color);
  margin-top: 10px;
}

.searchWrapper {
  margin-top: 20px;
  max-width: 700px;
}
