@import '../../../../../../../styles/shared';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  @include mq($from: desktop) {
    font-size: 18px;
  }
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 28px;
  margin-left: 15px;
  padding: var(--hub-button-text-top-offset) 12px 0;
  border: 1px solid #fff;
  border-radius: 26px;
}
