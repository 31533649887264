@import '../../../../../../../styles/shared';

.wrapper {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 0 10px;
  border-radius: 50px;
}

.text {
  padding: 0;
  font-weight: 400;
}

.outlined {
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--hub-product-module-secondary-color);
  border: 1px solid var(--hub-product-module-secondary-color);

  @include mq($from: desktop) {
    padding: 2px 10px;
  }
}

:global(.hub-product-card--hover) {
  .outlined {
    color: var(--hub-product-module-color-hover);
    border: 1px solid var(--hub-product-module-color-hover);
  }
}

.whiteFilled {
  background-color: #fff;
  border: 1px solid #4497ca;
  color: var(--hub-product-module-color);
  font-weight: 700;
}

.accented {
  background-color: var(--hub-product-module-accent-color);
  border: 1px solid var(--hub-product-module-accent-color);
  color: #fff;
  text-transform: uppercase;

  @include mq($from: desktop) {
    padding: 2px 10px;
  }
}

.tiny {
  // Uses default styles
}

.small {
  @include mq($from: desktop) {
    font-size: 14px;
    line-height: 24px;
  }
}

.medium {
  @include mq($from: desktop) {
    font-size: 16px;
    line-height: 24px;
  }
}

.large {
  @include mq($from: desktop) {
    font-size: 18px;
    line-height: 24px;
  }
}
