.wrapper {
  font-size: 16px;
}

.normal {
  color: #333;

  a {
    color: var(--hub-button-contained-background);
  }
}

.danger {
  color: var(--hub-authentication-error-color);
}
